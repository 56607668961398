import logo from "../images/logo-blue.png";
import casperGif from "../images/cropped-gif_casper.gif";
import { PageLink } from "../components/PageLink";
import smallLogo from "../images/w.svg";
import { LinkButton } from "../components/LinkButton";
import { useState } from "react";
import { SplitText } from "@cyriacbr/react-split-text";

const Landing = ({ setToggleForm }) => {
  let [checkState, setCheckState] = useState(true);
  let [readMe, setReadMe] = useState(false);

  let allText = {
    top: "Påmeldingen er stengt",
    intro: "Vi gleder oss til å møte alle påmeldte!",
    invite:
      "Arrangementet vil foregår lørdag 2. desember, mellom klokken 12 og 19 på Lade Tennisarena i Trondheim. Alle påmeldte vil få e-post med ytterligere informasjon.",
    slant: "Velg pulje for påmelding!",
    bottomText: `W Initiative er en humanitær stiftelse som skal bidra til økt livskvalitet for barn og unge. Grunnlegger og styreleder for stiftelsen er Gustav Magnar Witzøe. Les mer på `,
    consentText1: `Ved å melde deg på samtykker du til våre `,
  };

  return (
    <div className="min-h-screen bg-blue-100">
      <div className="bg-blue-100">
        <div className="py-6 text-center md:py-8 base-container">
          <h1 className="text-[10.8vw] uppercase md:text-7xl lg:text-8xl xl:text-[5.5vw] text-line text-line--1 text-beige-100">
            <SplitText>{allText.top}</SplitText>
          </h1>
        </div>
      </div>
      <div className="bg-beige-100">
        <div className="items-center pt-8 pb-12 md:py-20 base-grid base-container">
          <div class="col-span-12 md:col-span-4">
            <img
              className="w-8/12 mx-auto md:w-full"
              alt="casper-gif"
              src={casperGif}
            />
          </div>
          <div class="col-span-12 md:col-span-8 md:mt-0 mt-6 md:text-left">
            <h2 className="text-[26px] text-blue-100 uppercase md:text-3xl lg:text-6xl xl:text-7xl text-line text-line--2">
              {allText.intro}
            </h2>
            <p className="mt-4 md:font-semibold md:mt-8 lg:mt-12">
              Årets Tennisfest er nå fullbooket, og påmeldingen er stengt.
              Ønsker du å stå på venteliste, i tilfelle noen melder avbud? Send
              mail til{" "}
              <a
                className="inline-block underline"
                href="mailto:tennisfest@w-initiative.com"
              >
                tennisfest@w-initiative.com
              </a>{" "}
              med navn på foresatte, navn på barn og klassetrinn.
            </p>
          </div>
        </div>
      </div>
      <div className="text-white bg-blue-100">
        <div className="pt-5 pb-8 text-center md:py-10 base-container base-grid">
          <div class="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
            <img
              className="h-16 mx-auto brightness-0 invert"
              alt="w-initiative"
              src={smallLogo}
            />
            <p className="text-[12px] md:text-sm md:font-bold lg:text-base">
              {allText.bottomText}
              <a
                target={"_blank"}
                rel={"noopener noreferrer"}
                href="https://www.w-initiative.com"
                className="cursor-pointer hover:underline"
              >
                www.w-initiative.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
