import { useState } from "react";
import Form from "./pages/Form";
import Landing from "./pages/Landing";

const App = () => {
  let [toggleForm, setToggleForm] = useState(0);

  //
  return (
    <div className={`background-image ${toggleForm > 0 && "h-full"}`}>
      {toggleForm === 1 ? (
        <Form id="NimnNySr" />
      ) : toggleForm === 2 ? (
        <Form id="UThvcTDR" />
      ) : toggleForm === 3 ? (
        <Form id="nGHWaEmg" />
      ) : (
        <Landing setToggleForm={setToggleForm} />
      )}
    </div>
  );
};

export default App;
