import { PageLink } from "../components/PageLink";
import left from "../images/left-arrow.svg";

const Privacy = () => {
  return (
    <div className="h-screen background-image form">
      <div className="max-w-5xl px-4 py-10 mx-auto md:py-24">
        <div className="pb-4">
          <PageLink
            text={
              <img
                className="h-4 transition duration-300 ease-in-out delay-150 hover:-translate-x-1 hover:scale-110"
                alt="home"
                src={left}
              />
            }
            link="/"
          />
        </div>
        <p className="pb-4 text-xl md:text-2xl">Vilkår og betingelser</p>
        <div className="md:leading-9 md:text-xl">
          <p className="pb-4">
            Ved påmelding til Tennisfest, ber vi om følgende opplysninger: Navn
            på barn, klassetrinn, foresattes navn og foresattes kontaktinfo.
          </p>
          <p className="pb-4">
            Vi vil kun bruke opplysningene til å gjennomføre gratis tennisfest
            på Lade tennisanlegg i Trondheim, søndag 4 desember.
          </p>
          <p className="pb-4">
            Dette inkluderer bekreftelsemail, utsendelse av generell informasjon
            til deltakere, utskriving av deltakerlister og generell
            adgangskontroll. Vi behandler opplysningene konfidensielt og i
            samsvar med personvernregelverket. Det er kun Stiftelsen W
            Initiative og medarrangør Trondheim Tennisklubb som har tilgang til
            innsamlede data fra påmeldingen, og ingen individuelle besvarelser
            vil bli videreformidlet til andre. Opplysningene slettes når
            arrangementet er avsluttet, innen 16. desember 2022. Det vil bli
            tatt situasjons- og stemningsfoto, samt video fra arrangementet, til
            bruk på W Initiative og Trondheim Tennisklubbs kanaler. Det vil ikke
            bli tatt verken foto eller video av ett eller flere bestemte barn på
            en måte som gjør at barna kan identifiseres. Vi behandler
            opplysninger om deg basert på ditt samtykke.
          </p>
          <p className="pb-4">
            <p className="pb-4">Du har rett til:</p>
            <ul className="pl-10 list-disc">
              <li>
                Innsyn i hvilke personopplysninger som er registrert på deg.
              </li>
              <li>Å få utlevert en kopi av disse opplysningene.</li>
              <li>Å få rettet dine personopplysninger.</li>
              <li>Å få rettet slettet dine personopplysninger.</li>
              <li>
                Å sende inn klage til Datatilsynet om behandlingen av dine
                personopplysninger.
              </li>
            </ul>
          </p>
          <p className="pb-4">
            Databehandleransvarlig er Eirik Bøe Sletten, daglig leder i W
            Initiative. Ved spørsmål, kontakt{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:contact@w-initiative.com"
              className="text-blue-800 cursor-pointer"
            >
              contact@w-initiative.com
            </a>
            .
          </p>
          <p>
            Det tekniske verktøyet for påmeldingen er levert av TypeForm og
            Google Drive, og er underlagt deres respektive
            personvernerklæringer, se{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://admin.typeform.com/to/dwk6gt"
              className="text-blue-800 cursor-pointer"
            >
              contact@w-initiative.com
            </a>{" "}
            og{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://policies.google.com/terms"
              className="text-blue-800 cursor-pointer"
            >
              https://policies.google.com/terms.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
