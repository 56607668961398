// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './css/index.css';
// import "./css/App.css";
// import App from './App.js'

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App/>
//   </React.StrictMode>
// );


import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App.js";
import Privacy from "./pages/Privacy";
import './css/index.css';
import "./css/App.css";


export default function RouteApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<App />} />
          <Route path="/consent" element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouteApp />);